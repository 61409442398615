<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div
    style="min-height: 90vh"
    class="mx-auto flex min-h-full flex-1 items-center justify-center py-32 sm:px-4 lg:px-8"
  >
    <Loading v-if="isLoading" />
    <div v-else-if="getMenus.data?.length > 0">
      <div class="space-y-4 text-center">
        <p class="text-2xl font-bold">
          {{ me.current_role }}
        </p>
        <p class="text-gray-600">{{ me.office_code }} - {{ me.name }}</p>
        <p class="text-sm text-gray-500">Wilayah Harga {{ getWilayah }}</p>
      </div>
      <div
        class="my-8 mx-auto block w-full max-w-2xl space-y-4 rounded-lg py-6 px-4 sm:px-6"
        id="menu-available"
        style="margin-bottom: 180px"
      >
        <template v-if="role === 'Stockist'">
          <StockistMenu />
        </template>
        <div
          class="grid gap-6"
          :class="
            getMenus.data.filter((it) => !it.attributes.parent_id).length > 2
              ? 'grid-cols-2 md:grid-cols-3 '
              : ''
          "
        >
          <template
            v-for="(menu, index) in getMenus.data.filter(
              (it) => it.attributes.name.toLowerCase() !== 'admin'
            )"
          >
            <div v-if="menu.attributes.parent_id === null" :key="index">
              <button
                @click="setMenu(menu)"
                class="group flex w-full cursor-pointer flex-col items-center rounded-lg border border-gray-200 bg-white px-6 py-4 transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-110"
              >
                <div class="relative w-max">
                  <img
                    class="h-20 w-20"
                    :src="getPath(menu) + '.gif'"
                    :alt="'Gambar ' + menu.attributes.name"
                    loading="lazy"
                  />
                </div>
                <div class="relative">
                  <span
                    class="relative block text-center text-base font-semibold text-blue-900 group-hover:text-blue-500 dark:text-white"
                    >{{ menu.attributes.name }}</span
                  >
                </div>
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- /End replace -->
    <ChangePasswordAlert
      :visible="modalPasswordAlertVisible"
      @close="modalPasswordAlertVisible = false"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { StorageService } from '@/services/storage.service';
import Loading from '@/components/Loading.vue';
import ChangePasswordAlert from '@/components/menu/ChangePasswordAlert.vue';
import StockistMenu from '@/components/stockist/stockist-menu.vue';

export default {
  components: {
    Loading,
    StockistMenu,
    ChangePasswordAlert,
  },
  data() {
    const role = StorageService.getRole();
    return {
      isLoading: false,
      baseUrl: process.env.VUE_APP_API_URL,
      role,
      modalPasswordAlertVisible: false,
    };
  },
  created() {
    const user = StorageService.getUser();

    // kalo passwordnya blm diubah user,
    // suruh usernya ganti password dgn cara menampilkan alert
    if (!user.is_password_changed) {
      this.modalPasswordAlertVisible = true;
    }
  },
  computed: {
    ...mapGetters({
      getMenusByOfficeCategory: 'office_categories/getMenusByOfficeCategory',
      getRolesByUser: 'users/getRolesByUser',
      getMenus: 'menus/getMenus',
      me: 'auth/getUser',
    }),
    getWilayah() {
      const list = ['Jawa', 'I', 'II', 'III', 'IV', 'loading...'];
      return list[this.me.office_area_code ?? 5];
    },
  },
  methods: {
    ...mapActions({
      fetchMenus: 'menus/fetchMenus',
    }),
    ...mapMutations({
      authSetMenu: 'auth/setMenu',
    }),
    setMenu(menu, to) {
      this.authSetMenu(menu);

      const menus = {
        Superadmin: '/superadmin',
        Admin: '/admin',
        Gudang: '/gudang',
        Kasir: '/kasir',
        Penjualan: '/penjualan',
        Pembelian: '/pembelian',
        Pelayanan: '/kantor-pelayanan',
        'Barang Masuk': '/barang-masuk',
        'Transaksi Penjualan': '/pengiriman-barang',
        'Retur Barang': '/retur-barang',
        Laporan: '/laporan',
        'Cash Bill': '/cashbill',
        Bonus: '/bonus',
        'Stock Stockist': '/stock-stockist',
      };

      if (Object.prototype.hasOwnProperty.call(menus, menu.attributes.name)) {
        this.$router.push(to || menus[menu.attributes.name]);
      }
    },
    searchMenu(menu1) {
      return this.getMenusByOfficeCategory.data.some(
        (menu) => menu.attributes.name === menu1.name
      );
    },
    getPath(menu) {
      let filePath = `${this.baseUrl}${menu.attributes.icon}`;
      return filePath.lastIndexOf('.svg') !== -1
        ? filePath.substring(0, filePath.lastIndexOf('.svg'))
        : `${filePath}`;
    },
    compareData(menus1, menus2) {
      const data = menus1.filter((menu1) =>
        menus2.some((menu2) => menu1.name === menu2.attributes.name)
      );
      if (data.length === 1) {
        setTimeout(() => this.setMenu(data[0].name), 500);
      }
      this.isLoading = false;
    },
  },
};
</script>
