<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="my-6 rounded-lg bg-white p-4">
      <div class="border-b border-gray-200 py-5">
        <div class="flex flex-wrap items-center justify-between sm:flex-nowrap">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Komplain Barang
          </h3>
        </div>
      </div>

      <h3 class="text-sm font-semibold">
        Kode Barang: {{ stockMovements.attributes.code }}
      </h3>
      <div class="mb-5 rounded-lg bg-white py-4">
        <div class="flex flex-col">
          <div
            class="mt-6 mb-4 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6"
          >
            <div class="sm:col-span-6">
              <div class="field-inset-default w-full">
                <label
                  for="tanggal"
                  class="block text-xs font-bold text-gray-700"
                >
                  Perihal
                </label>
                <input
                  v-model="payload.data.attributes.title"
                  ref="focusNamaStockist"
                  type="text"
                  name="name"
                  id="name"
                  class="block w-full border-0 px-0 py-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan Perihal"
                />
              </div>
            </div>

            <div class="sm:col-span-6">
              <div class="field-inset-default relative">
                <label
                  for="nama_stockist"
                  class="block text-xs font-bold text-gray-700"
                >
                  Detail Komplain
                </label>
                <textarea
                  v-model="payload.data.attributes.message"
                  name="about"
                  rows="3"
                  class="mt-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="field-inset-default">
            <label class="mb-2 block text-xs font-bold text-gray-700">
              Bukti File(wajib bertipe jpg, png)
            </label>
            <FilePond @onAddFile="onAddFile" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <button
        @click="createDispute"
        type="submit"
        class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
      >
        Simpan
      </button>
      <button
        @click="() => $router.go(-1)"
        class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
      >
        Kembali
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';
import FilePond from '@/components/base/FilePond.vue';

import dayjs from 'dayjs';
export default {
  name: 'Disputes',
  components: {
    FilePond,
  },
  data() {
    const destination_warehouse_id = StorageService.getUser().current_warehouse;
    const userId = StorageService.getUser().office_id;
    return {
      destination_warehouse_id,
      stockMovements: {
        attributes: {
          code: '',
        },
      },
      payload: {
        data: {
          type: 'disputes',
          attributes: {
            title: '',
            message: '',
            office_sender_id: userId,
            office_receiver_id: '{{office_pusat_id}}',
          },
          relationships: {
            'stock-movement': {
              data: {
                type: 'stock-movements',
                id: this.$router.currentRoute.params.id,
              },
            },
          },
        },
      },
      file: new FormData(),
    };
  },

  computed: {
    ...mapGetters({
      getOffices: 'offices/getOffices',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      receiveStockMovement: 'stock_movements/receiveStockMovement',
      fetchOffices: 'offices/fetchOffices',
      fetchStockMovement: 'stock_movements/fetchStockMovement',
      createDisputes: 'disputes/createDisputes',
      uploadFileDisputes: 'disputes/uploadFileDisputes',
      createAlert: 'alert/createAlert',
    }),
    async createDispute() {
      const promise1 = this.createDisputes({ payload: this.payload });
      const promise2 = this.uploadFileDisputes({ payload: this.file });

      try {
        await Promise.all([promise1, promise2]);
      } catch (error) {
        this.createAlert({
          data: JSON.stringify(error.message),
          type: 'warning',
        });
      } finally {
        this.$router.go(-1);
      }
    },
    onAddFile(out) {
      // nambah file ke local state tiap file dimasukin
      this.file.append('dispute_images[]', out.file);
    },
  },
  created() {
    const stockMovementsId = this.$router.currentRoute.params.id;
    this.file.append('stock_movement_id', stockMovementsId);
    if (stockMovementsId) {
      this.fetchStockMovement({ id: stockMovementsId }).then((res) => {
        this.stockMovements = res.data.data;
      });
      this.fetchOffices({ office_category: 'Pusat' }).then((res) => {
        this.payload.data.attributes.office_receiver_id = res.data.data[0].id;
      });
    }
  },
};
</script>
