<template>
  <div v-if="getUser.data?.attributes" class="flex justify-center gap-2">
    <div>
      <div class="relative">
        <img
          v-if="getProfileUrl"
          class="inline-block h-20 w-20 rounded-full"
          :src="getProfileUrl"
          id="profile_pic"
          alt="Your current profile image"
        />
        <img
          v-else-if="me.sex == 'female'"
          class="inline-block h-20 w-20 rounded-full"
          src="@/assets/images/default-female.png"
          id="profile_pic"
          alt="Your current profile image"
        />
        <img
          v-else
          class="inline-block h-20 w-20 rounded-full"
          src="@/assets/images/default-male.png"
          id="profile_pic"
          alt="Your current profile image"
        />
        <input
          @change="handleChangeFile"
          type="file"
          id="profile_pic"
          class="hidden"
        />
        <base-button
          @click="handleProfileUpload"
          size="sm"
          class="edit-picture"
          color="white"
        >
          Edit
        </base-button>
      </div>
    </div>
    <div class="pb-12 sm:px-4">
      <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-md font-semibold leading-6 text-gray-900">
            Ubah Profil
          </h3>
        </div>
        <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl class="sm:divide-y sm:divide-gray-200">
            <div
              class="flex items-center py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
            >
              <dt class="text-sm font-bold text-gray-700">Kode Kantor</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <input
                  :value="getOffice.data?.attributes.code"
                  type="text"
                  disabled
                  name="office_code"
                  class="block w-full max-w-lg rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                />
              </dd>
            </div>
            <div
              class="flex items-center py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
            >
              <dt class="text-sm font-bold text-gray-700">Nama Lengkap</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <input
                  v-model="user.name"
                  type="text"
                  name="name"
                  class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 disabled:bg-gray-100 sm:text-sm"
                  :disabled="disabledEdit"
                />
              </dd>
            </div>
            <div
              class="flex items-center py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
            >
              <dt class="text-sm font-bold text-gray-700">Email</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <input
                  v-model="user.email"
                  name="email"
                  type="email"
                  class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-100 sm:text-sm"
                  :disabled="disabledEdit"
                />
              </dd>
            </div>
            <div
              class="flex items-center py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
            >
              <dt class="text-sm font-bold text-gray-700">Kantor</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <input
                  v-model="user.office"
                  type="text"
                  disabled
                  name="office"
                  class="block w-full max-w-lg rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                />
              </dd>
            </div>
            <div
              class="flex items-center py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
            >
              <dt class="text-sm font-bold text-gray-700">Role</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <input
                  v-model="user.role"
                  type="text"
                  disabled
                  name="role"
                  class="block w-full max-w-lg rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                />
              </dd>
            </div>
            <div
              class="flex items-center py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
            >
              <dt class="text-sm font-bold text-gray-700">No HP</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <input
                  v-model="user.hp"
                  type="text"
                  name="hp"
                  class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 disabled:bg-gray-100 sm:text-sm"
                  :disabled="disabledEdit"
                />
              </dd>
            </div>
            <div
              class="flex items-center py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
            >
              <dt class="text-sm font-bold text-gray-700">Alamat</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <textarea
                  v-model="user.address"
                  name="about"
                  rows="3"
                  class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 disabled:bg-gray-100 sm:text-sm"
                  :disabled="disabledEdit"
                ></textarea>
              </dd>
            </div>
            <div
              v-if="!disabledEdit"
              class="flex justify-end py-4 sm:py-5 sm:px-6"
            >
              <base-button @click="updateProfile" type="button">
                Update
              </base-button>
            </div>
          </dl>
        </div>
      </div>

      <div class="mt-12 overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-md font-semibold leading-6 text-gray-900">
            Ubah Kata Sandi
          </h3>
        </div>
        <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl class="sm:divide-y sm:divide-gray-200">
            <div
              class="flex items-center py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
            >
              <dt class="text-sm font-bold text-gray-700">Kata sandi baru</dt>
              <dd
                class="relative mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
              >
                <svg
                  class="absolute inset-y-0 top-1 flex h-7 w-7 cursor-pointer items-center pl-3"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18 8C18 11.3137 15.3137 14 12 14C11.3938 14 10.8087 13.9101 10.2571 13.7429L8 16H6V18H2V14L6.25707 9.74293C6.08989 9.19135 6 8.60617 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8ZM12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6C13.1046 6 14 6.89543 14 8C14 8.55228 14.4477 9 15 9C15.5523 9 16 8.55228 16 8C16 5.79086 14.2091 4 12 4Z"
                    fill="#9CA3AF"
                  />
                </svg>
                <input
                  v-model="user.newPassword"
                  type="password"
                  name="password"
                  class="block w-full max-w-lg rounded-md border-gray-300 pl-9 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                />
              </dd>
            </div>
            <form>
              <div
                class="flex items-center py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
              >
                <dt class="text-sm font-bold text-gray-700">
                  Konfirmasi kata sandi baru
                </dt>
                <dd
                  class="relative mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                >
                  <svg
                    class="absolute inset-y-0 top-1 flex h-7 w-7 cursor-pointer items-center pl-3"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18 8C18 11.3137 15.3137 14 12 14C11.3938 14 10.8087 13.9101 10.2571 13.7429L8 16H6V18H2V14L6.25707 9.74293C6.08989 9.19135 6 8.60617 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8ZM12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6C13.1046 6 14 6.89543 14 8C14 8.55228 14.4477 9 15 9C15.5523 9 16 8.55228 16 8C16 5.79086 14.2091 4 12 4Z"
                      fill="#9CA3AF"
                    />
                  </svg>
                  <input
                    v-model="user.confirmPassword"
                    type="password"
                    name="password_confirmation"
                    class="block w-full max-w-lg rounded-md border-gray-300 pl-9 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />
                </dd>
              </div>
              <div class="flex justify-end py-4 sm:py-5 sm:px-6">
                <base-button @click="updatePassword" type="button">
                  Update
                </base-button>
              </div>
            </form>
          </dl>
        </div>
      </div>

      <div
        class="divider-gray-200 mt-12 divide-y overflow-hidden bg-white shadow sm:rounded-lg"
      >
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-md font-semibold leading-6 text-gray-900">
            Riwayat Login
          </h3>
        </div>
        <div
          class="inline-block min-w-full px-4 py-5 align-middle sm:px-6 lg:px-8"
        >
          <Datatable :paginated="false" :footer="false">
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Tanggal
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    IP Address
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Perangkat
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody>
                <tr
                  class="bg-white"
                  v-for="(data, index) in getAll['user-logs'].data"
                  :key="index"
                >
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  >
                    {{ dayjs(data.attributes.createdAt).format('ll LT') }}
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  >
                    {{ data.attributes.ipAddress }}
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  >
                    {{ getUserAgent(data.user_agent) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </Datatable>
        </div>
      </div>

      <transition name="fade">
        <div
          v-if="success"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
            >
              <div>
                <div
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
                >
                  <svg
                    class="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <slot name="heading"> Data Berhasil diubah </slot>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <div class="flex items-center gap-2">
                  <base-button fullwidth @click="closeModal">
                    Selesai
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div
          v-if="failed"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
            >
              <div>
                <div
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-red-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <slot name="heading">
                    {{ getError }}
                  </slot>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <div class="flex items-center gap-2">
                  <base-button fullwidth @click="closeModal">
                    Selesai
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <loading v-if="isLoading"></loading>
    </div>
  </div>
</template>

<script>
import { StorageService } from '@/services/storage.service';
import { mapActions, mapGetters } from 'vuex';
import Datatable from '@/components/base/Datatable';
import dayjs from 'dayjs';
import BaseButton from '@/components/base/BaseButton.vue';
import UAParser from 'ua-parser-js';

export default {
  name: 'Users',
  components: {
    Datatable,
    BaseButton,
  },
  data() {
    return {
      success: false,
      failed: false,
      user: {
        id: null,
        name: null,
        email: null,
        office: null,
        role: null,
        hp: null,
        address: null,
        newPassword: null,
        confirmPassword: null,
      },
      recent_login: [],
      baseUrl: process.env.VUE_APP_API_URL,
    };
  },
  created() {
    this.setUser(StorageService.getUser());
    this.fetchAll({
      schema: 'user-logs',
    });
  },
  computed: {
    ...mapGetters({
      getAll: 'main/getAll',
      getOffice: 'offices/getOffice',
      isLoadingOffice: 'offices/getLoading',
      isLoadingUser: 'users/getLoading',
      getUser: 'users/getUser',
      me: 'auth/getUser',
    }),
    getProfileUrl() {
      return this.getUser.data?.attributes.avatar
        ? this.baseUrl +
            '/api/v1/avatars/' +
            this.getUser.data?.attributes.avatar
        : null;
    },
    disabledEdit() {
      return true;
    },
    isLoading() {
      return this.isLoadingOffice || this.isLoadingUser;
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchAll: 'main/fetchAll',
      updateUser: 'users/updateUser',
      fetchUser: 'users/fetchUser',
      uploadAvatar: 'users/uploadAvatar',
    }),
    getUserAgent(data) {
      var panggil = new UAParser(data);
      const browser = panggil.getBrowser().name;
      const os = panggil.getOS().name;
      return browser + '/' + os;
      // var result = panggil.getResult();
      // return console.log(result);
    },
    handleChangeFile(event) {
      const data = new FormData();
      data.append('avatar', event.target.files[0]);
      this.uploadAvatar({ id: this.getUser.data.id, payload: data });
    },
    handleProfileUpload() {
      const inputFile = document.getElementById('profile_pic');
      inputFile.click();
    },
    closeModal() {
      this.success = this.failed = false;
      //   location.reload();
    },
    setUser(user) {
      this.user.id = user.uuid;
      this.user.name = user.name;
      this.user.email = user.email;
      this.user.role = user.current_role;
      this.user.hp = user.phone;
      this.fetchOfficeById({
        office_id: user.office_id,
      }).then((response) => {
        this.user.office = response.data.data.attributes.name;
        this.user.hp = response.data.data.attributes.phone;
        this.user.address = response.data.data.attributes.address;
      });
      this.fetchUser({
        userId: user.uuid,
        params: {
          // include: 'user-logs',
        },
      }).then((response) => {
        this.recent_login = response.data.data.attributes.user_logs;
      });
    },
    updateProfile() {
      const me = StorageService.getUser();
      const payload = {
        data: {
          type: 'users',
          id: this.user.id,
          attributes: {
            name: this.user.name,
            email: this.user.email,
          },
        },
      };
      this.updateUser(payload).then((response) => {
        if (response) {
          me.name = this.user.name;
          me.email = this.user.email;
          StorageService.saveUser(me);
          this.success = true;
        } else {
          this.failed = true;
        }
      });
    },
    updatePassword() {
      const payload = {
        data: {
          type: 'users',
          id: this.user.id,
          attributes: {
            password: this.user.newPassword,
            password_confirmation: this.user.confirmPassword,
          },
        },
      };
      this.updateUser(payload).then((response) => {
        if (response) {
          this.success = true;
        } else {
          this.failed = true;
        }
      });
    },
  },
};
</script>
<style scoped>
.edit-picture {
  position: absolute;
  bottom: -10px;
  right: -10px;
}
</style>
