const LayoutDefault = () =>
  import(/* webpackChunkName: "mitra-usaha" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "mitra-usaha" */ '@/layouts/LayoutData.vue');

const Dashboard = () =>
  import(
    /* webpackChunkName: "mitra-usaha" */ '@/views/member/gudang/dashboard/Index.vue'
  );

const Pembelian = () =>
  import(
    /* webpackChunkName: "mitra-usaha" */ '@/views/member/gudang/cash-bill/Index.vue'
  );

const PenerimaanBarangMasuk = () =>
  import(
    /* webpackChunkName: "mitra-usaha" */ '@/views/member/gudang/penerimaan/Index.vue'
  );
const TambahPenerimaanBarangMasuk = () =>
  import(
    /* webpackChunkName: "mitra-usaha" */ '@/views/member/gudang/penerimaan/TambahPenerimaanBarangMasuk.vue'
  );

const Stock = () =>
  import(
    /* webpackChunkName: "mitra-usaha" */ '@/views/stockist/gudang/stock/Index.vue'
  );
let root = '/gudang';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Gudang',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/stock/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Stock',
    },
    children: [
      {
        path: '',
        component: Stock,
        meta: {
          auth: true,
          title: 'Stock | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/cashbill/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Cash Bill',
    },
    children: [
      {
        path: '',
        component: Pembelian,
        meta: {
          auth: true,
          title: 'Cash Bill | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/statement-komisi/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Statement Komisi',
    },
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "mitra-usaha" */ '@/views/member/gudang/statement-komisi/index.vue'
          ),
        meta: {
          auth: true,
          title: 'Statement Komisi | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/pajak/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Pajak',
    },
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "mitra-usaha" */ '@/views/member/gudang/pajak/index.vue'
          ),
        meta: {
          auth: true,
          title: 'Pajak | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/rincian-pajak/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Rincian Pajak',
    },
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "mitra-usaha" */ '@/views/member/gudang/rincian-pajak/index.vue'
          ),
        meta: {
          auth: true,
          title: 'Rincian Pajak | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/jaringan/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Jaringan',
    },
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "mitra-usaha" */ '@/views/member/gudang/jaringan/index.vue'
          ),
        meta: {
          auth: true,
          title: 'Jaringan | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/info-permendag/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Jaringan',
    },
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "mitra-usaha" */ '@/views/member/gudang/jaringan/index.vue'
          ),
        meta: {
          auth: true,
          title: 'Jaringan | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/bulan-lalu/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Jaringan',
    },
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "mitra-usaha" */ '@/views/member/gudang/jaringan/index.vue'
          ),
        meta: {
          auth: true,
          title: 'Jaringan | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/web-replika/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Jaringan',
    },
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "mitra-usaha" */ '@/views/member/gudang/jaringan/index.vue'
          ),
        meta: {
          auth: true,
          title: 'Jaringan | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/download-area/',
    component: LayoutData,
    meta: {
      breadcrumb: 'Jaringan',
    },
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "mitra-usaha" */ '@/views/member/gudang/jaringan/index.vue'
          ),
        meta: {
          auth: true,
          title: 'Jaringan | Gudang',
          layout: LayoutDefault,
        },
      },
    ],
  },
  {
    path: root + '/penerimaan/',
    component: LayoutData,
    children: [
      {
        path: '',
        component: LayoutData,
        meta: {
          breadcrumb: 'Penerimaan Barang dari Stockist',
        },
        children: [
          {
            path: '',
            component: PenerimaanBarangMasuk,
            meta: {
              auth: true,
              title: 'Penerimaan Barang Masuk | Gudang',
              layout: LayoutDefault,
            },
          },
          {
            path: 'tambah-barang-masuk',
            component: TambahPenerimaanBarangMasuk,
            meta: {
              auth: true,
              title: 'Tambah Barang Masuk | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Tambah Penerimaan Barang',
            },
          },
          {
            path: ':id',
            component: TambahPenerimaanBarangMasuk,
            meta: {
              auth: true,
              title: 'Tambah Barang Masuk | Gudang',
              layout: LayoutDefault,
              breadcrumb: 'Tambah Penerimaan Barang',
            },
          },
        ],
      },
    ],
  },
];
