const LayoutDefault = () =>
  import(/* webpackChunkName: "stockist" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "stockist" */ '@/layouts/LayoutData.vue');
const Dashboard = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/kasir/dashboard/Index.vue'
  );

const Pembayaran = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/kasir/pembayaran/Index.vue'
  );
const DetailPembayaran = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/kasir/pembayaran/faktur-penjualan/DetailPembayaran.vue'
  );
const DetailPenjualan = () =>
  import(
    /* webpackChunkName: "stockist" */ '@/views/stockist/kasir/pembayaran/faktur-penjualan/DetailPenjualan.vue'
  );

let root = '/kasir';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Kasir',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/pembayaran/',
    component: LayoutData,
    children: [
      {
        path: '',
        component: Pembayaran,
        meta: {
          layout: LayoutDefault,
          breadcrumb: 'Pembayaran',
        },
      },
      {
        path: 'faktur-penjualan/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Faktur Penjualan',
        },
        children: [
          {
            path: ':id/',
            component: LayoutData,
            meta: {
              breadcrumb: 'Detail Penjualan',
            },
            children: [
              {
                path: '',
                component: DetailPenjualan,
                meta: {
                  auth: true,
                  title: 'Detail Faktur Penjualan | Kasir',
                  layout: LayoutDefault,
                },
              },
              {
                path: 'faktur-pembayaran/:id_pembayaran',
                component: DetailPembayaran,
                meta: {
                  auth: true,
                  title: 'Detail Faktur Pembayaran | Kasir',
                  layout: LayoutDefault,
                  breadcrumb: 'Detail Faktur Pembayaran',
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
