<template>
  <select
    v-model="selected"
    class="block bg-white sm:text-sm"
    :disabled="disabled"
    :id="id"
    :class="[
      inset
        ? 'border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm'
        : 'rounded-md border border-gray-300 py-0 focus:border-green-500 focus:ring-green-500',
      disabled ? 'bg-gray-100' : '',
      shadow ? (inset ? '' : 'shadow') : '',
      expand ? 'w-full' : '',
      customHeight ? customHeight : 'h-10',
    ]"
    :style="{ width: fit ? 'fit-content' : null }"
    v-on:change="handleChange"
    v-on:focus="handleFocus"
  >
    <slot>
      <option v-for="option in options" :key="option.key" :value="option.value">
        {{ option.label }}
      </option>
    </slot>
  </select>
</template>

<script>
export default {
  name: 'BaseSelect',
  props: {
    value: null,
    id: null,
    options: {
      type: Array,
      required: false,
    },
    inset: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
    expand: {
      type: Boolean,
      default: true,
    },
    fit: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customHeight: {
      type: [String, Boolean],
      default: null,
    },
  },
  emits: ['input', 'change'],
  computed: {
    selected: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleChange: function () {
      this.$emit('change');
    },
    handleFocus: function () {
      this.$emit('focus');
    },
  },
};
</script>
