import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  productCategories: {
    data: [],
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getProductCategories: (state) => {
    return state.productCategories;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setProductCategories: (state, data) => (state.productCategories = data),
};

const actions = {
  async fetchProductCategories({ commit }) {
    commit('setLoader', true);
    let response = await ApiService.get('api/v1/product-categories');
    commit('setProductCategories', response.data);
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
