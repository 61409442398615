const LayoutDefault = () =>
  import(/* webpackChunkName: "admin-kasir" */ '@/layouts/LayoutDefault.vue');
const LayoutData = () =>
  import(/* webpackChunkName: "admin-kasir" */ '@/layouts/LayoutData.vue');
const Dashboard = () =>
  import(/* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/Index.vue');
const Data = () =>
  import(/* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/Data.vue');
// Faktur Penjualan Pusat ke Stockist
const FakturPenjualanPusat = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/pembayaran/faktur-penjualan-pusat/Index.vue'
  );
const DetailPembayaran = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/pembayaran/faktur-penjualan-pusat/DetailPembayaran.vue'
  );
const DetailPenjualan = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/pembayaran/faktur-penjualan-pusat/DetailPenjualan.vue'
  );

// Laporan
const Laporan = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/laporan/Index.vue'
  );
const TambahLaporan = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/laporan/TambahLaporan.vue'
  );
const LaporanRestock = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/laporan/restock/index.vue'
  );
const TambahLaporanRestock = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/laporan/restock/Tambah.vue'
  );
const LaporanPembayaranHarian = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/laporan/LaporanPembayaranHarian.vue'
  );
const LaporanKeuanganStockistGlobal = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/laporan/laporan-keuangan-stockist/LaporanKeuanganGlobal.vue'
  );
const LaporanKeuanganStockistPerStockist = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/laporan/laporan-keuangan-stockist/LaporanKeuanganPerStockist.vue'
  );
const LaporanGL = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/laporan/LaporanGL.vue'
  );
const LaporanOmzetSc = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/laporan/LaporanOmzetSc.vue'
  );

// Validasi Laporan Penjualan
const LaporanPenjualanSC = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/validasi/laporan-penjualan-sc/Index.vue'
  );
const LaporanPenjualanKP = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/validasi/laporan-penjualan-kp/Index.vue'
  );

const TopupStockist = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/topup/stockist/Index.vue'
  );
const TambahTopupStockist = () =>
  import(
    /* webpackChunkName: "admin-kasir" */ '@/views/pusat/kasir/topup/stockist/Tambah.vue'
  );

let root = '/kasir';

export default [
  {
    path: root + '/',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard | Kasir',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/data',
    component: Data,
    meta: {
      auth: true,
      title: 'Data Page | Kasir',
      layout: LayoutDefault,
    },
  },
  {
    path: root + '/validasi/',
    component: LayoutData,
    children: [
      {
        path: 'laporan-kasir-sc',
        component: LaporanPenjualanSC,
        meta: {
          auth: true,
          title: 'Laporan Penjualan SC | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Validasi Laporan Penjualan SC',
        },
      },
      {
        path: 'laporan-kasir-kp',
        component: LaporanPenjualanKP,
        meta: {
          auth: true,
          title: 'Laporan Penjualan KP | Penjualan Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Validasi Laporan Penjualan KP',
        },
      },
    ],
  },
  {
    path: root + '/pembayaran/',
    component: LayoutData,
    children: [
      {
        path: 'faktur-penjualan-pusat-ke-stockist/',
        component: LayoutData,
        meta: {
          breadcrumb: 'Faktur Penjualan ke Stockist',
        },
        children: [
          {
            path: '',
            component: FakturPenjualanPusat,
            meta: {
              auth: true,
              title: 'Faktur Penjualan ke Stockist | Kasir',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: DetailPenjualan,
            meta: {
              auth: true,
              breadcrumb: 'Detail Faktur Penjualan Pusat ke Stockist',
              title: 'Detail Faktur Penjualan Pusat ke Stockist | Kasir',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id/faktur-pembayaran/:id_pembayaran',
            component: DetailPembayaran,
            meta: {
              auth: true,
              title: 'Detail Faktur Pembayaran | Kasir',
              layout: LayoutDefault,
              breadcrumb: 'Detail Faktur Pembayaran',
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/laporan-harian',
    component: LaporanPembayaranHarian,
    meta: {
      auth: true,
      title: 'Laporan Pembayaran Harian | Kasir',
      layout: LayoutDefault,
      breadcrumb: 'Laporan Pembayaran Harian',
    },
  },
  {
    path: root + '/laporan-keuangan-stockist',
    component: LayoutData,
    meta: {
      auth: true,
      title: 'Laporan Keuangan Stockist | Kasir',
      layout: LayoutDefault,
      breadcrumb: 'Laporan Keuangan Stockist',
    },
    children: [
      {
        path: 'laporan-keuangan-global',
        component: LaporanKeuanganStockistGlobal,
        meta: {
          auth: true,
          title: 'Laporan Keuangan Global | Kasir',
          layout: LayoutDefault,
          breadcrumb: 'Laporan Keuangan Global',
        },
      },
      {
        path: 'laporan-keuangan-per-stockist',
        component: LaporanKeuanganStockistPerStockist,
        meta: {
          auth: true,
          title: 'Laporan Keuangan Per Stockist | Kasir',
          layout: LayoutDefault,
          breadcrumb: 'Laporan Keuangan Per Stockist',
        },
      },
    ],
  },
  {
    path: root + '/laporan-gl',
    component: LaporanGL,
    meta: {
      auth: true,
      title: 'Laporan GL | Kasir',
      layout: LayoutDefault,
      breadcrumb: 'Laporan GL',
    },
  },
  {
    path: root + '/laporan-omzet-sc',
    component: LaporanOmzetSc,
    meta: {
      auth: true,
      title: 'Laporan Omzet SC | Kasir',
      layout: LayoutDefault,
      breadcrumb: 'Laporan Omzet SC',
    },
  },
  {
    path: root + '/laporan/',
    component: LayoutData,
    children: [
      {
        path: '',
        component: Laporan,
        meta: {
          auth: true,
          title: 'Laporan | Kasir',
          layout: LayoutDefault,
        },
      },
      {
        path: 'tambah-laporan',
        component: TambahLaporan,
        meta: {
          auth: true,
          title: 'Tambah Faktur SC | Kasir',
          layout: LayoutDefault,
        },
      },
      {
        path: 'restock/',
        component: LayoutData,
        children: [
          {
            path: '',
            component: LaporanRestock,
            meta: {
              auth: true,
              title: 'Restock | Kasir',
              layout: LayoutDefault,
            },
          },
          {
            path: 'tambah-restock',
            component: TambahLaporanRestock,
            meta: {
              auth: true,
              title: 'Tambah Restock | Kasir',
              layout: LayoutDefault,
            },
          },
          {
            path: ':id',
            component: TambahLaporanRestock,
            meta: {
              auth: true,
              title: 'Edit Restock | Kasir',
              layout: LayoutDefault,
            },
          },
        ],
      },
    ],
  },
  {
    path: root + '/topup/',
    component: LayoutData,
    children: [
      {
        path: 'saldo-stockist',
        component: TopupStockist,
        meta: {
          auth: true,
          title: 'Topup Stockist | Kasir Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Topup Saldo Stockist',
        },
      },
      {
        path: 'saldo-stockist/tambah',
        component: TambahTopupStockist,
        meta: {
          auth: true,
          title: 'Tambah Saldo Stockist | Kasir Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Saldo Stockist',
        },
      },
    ],
  },
  {
    path: root + '/topup/',
    component: LayoutData,
    children: [
      {
        path: 'saldo-retur-stockist',
        component: TopupStockist,
        meta: {
          auth: true,
          title: 'Topup Stockist | Kasir Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Topup Saldo Stockist',
        },
        props: {
          title: 'Topup Retur Stockist',
        },
      },
      {
        path: 'saldo-retur-stockist/tambah',
        component: TambahTopupStockist,
        meta: {
          auth: true,
          title: 'Tambah Saldo Stockist | Kasir Pusat',
          layout: LayoutDefault,
          breadcrumb: 'Tambah Saldo Stockist',
        },
      },
    ],
  },
];
