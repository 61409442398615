<template>
  <div class="flex min-h-screen bg-white">
    <div class="relative hidden w-0 flex-1 bg-gray-50 lg:flex">
      <div class="flex w-full items-center justify-center p-8">
        <ForgotIllustration class="object-cover" alt="Login Illustrator" />
      </div>
    </div>
    <div
      class="flex relative flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-10"
    >
      <div class="absolute top-0 py-12">
        <router-link
          :to="'/login'"
          class="focus:outline-none inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-green-500 focus:ring-offset-1"
        >
          <!-- Heroicon name: solid/mail -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2 h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 16l-4-4m0 0l4-4m-4 4h18"
            />
          </svg>
          Kembali
        </router-link>
      </div>
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div class="flex flex-col">
          <div class="flex justify-center">
            <NasaLogo class="h-24" alt="Workflow" />
          </div>
          <h2 class="mt-6 text-xl font-extrabold text-gray-900">
            Lupa Password
          </h2>
          <p class="mt-2 text-sm text-gray-600">
            Masukan alamat <b>Email terdaftar</b> atau <b>No. Handphone</b> dan
            kami akan mengirimkan instruksi untuk mengganti password melalui
            email.
          </p>
        </div>

        <div class="mt-6">
          <form action="#" method="POST" class="space-y-6">
            <div>
              <label
                for="email"
                class="block text-sm font-medium text-gray-700"
              >
                Alamat Email
              </label>
              <div class="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                class="focus:outline-none flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Kirim
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ForgotIllustration, NasaLogo } from '@/assets/images';
export default {
  name: 'ForgotPassword',
  components: {
    ForgotIllustration,
    NasaLogo,
  },
};
</script>
