import ApiService from '@/services/api.service';

const state = {
  data: {
    meta: {
      page: {
        currentPage: 1,
        from: 1,
        lastPage: 1,
        perPage: 5,
        to: 5,
        total: 5,
      },
    },
  },
  loading: false,
};

const getters = {
  getData: (state) => state.data,
  getLoading: (state) => state.loading,
};

const mutations = {
  setData: (state, payload) => (state.data = payload),
  setLoader: (state, payload) => (state.loading = payload),
};

const actions = {
  async fetchBalanceTrxes(
    { commit },
    { pageNumber, pageSize, search, trxType, office_uuid, ...others } = {}
  ) {
    commit('setLoader', true);
    const params = { ...others };
    let filter = [];
    let page = [];

    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    if (trxType && trxType !== '') filter['trx_type'] = trxType;
    if (office_uuid && office_uuid !== '') filter['office_uuid'] = office_uuid;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    Object.assign(params, { sort: '-createdAt' });
    Object.assign(params, { include: 'office' });

    let response = await ApiService.get(`api/v1/balance-trxes`, { params });
    commit('setData', response.data);
    commit('setLoader', false);
    return response;
  },
  async approveTopup({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(
        `api/v1/balance-trxes/${payload.id}/-actions/approve`
      );
      dispatch(
        'alert/createAlert',
        { data: 'Topup berhasil diapprove' },
        { root: true }
      );
      commit('setData', response.data);
      commit('setLoader', false);
      return response;
    } catch (e) {
      dispatch('alert/createAlert', { data: e.response.data }, { root: true });
    }
  },
  async fetchBalanceTrx(store, { id, params }) {
    try {
      const res = await ApiService.get(`api/v1/balance-trxes/${id}`, {
        params,
      });

      return res.data;
    } catch (err) {
      //
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
