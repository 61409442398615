import ApiService from '@/services/api.service';

const state = {
  isLoading: false,
  error: null,
  stockistsCenter: {
    data: [],
    meta: {
      page: {},
    },
  },
  stockistCenter: {},
  officeCategory: {
    data: [],
  },
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getStockistsCenter: (state) => {
    return state.stockistsCenter;
  },
  getStockistCenter: (state) => {
    return state.stockistCenter;
  },
  getOfficeCategory: (state) => {
    return state.officeCategory;
  },
  getError: (state) => {
    return state.error;
  },
};

const mutations = {
  setLoader: (state, status) => (state.isLoading = status),
  setStockistsCenter: (state, data) => (state.stockistsCenter = data),
  setStockistCenter: (state, data) => (state.stockistCenter = data),
  setOfficeCategory: (state, data) => (state.officeCategory = data),
  setError: (state, data) => (state.error = data),
};

const actions = {
  async createStockistCenter({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post('api/v1/offices', payload);
      commit('setStockistCenter', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setStockistCenter', {});
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
  async updateStockistCenter({ commit }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.patch(
        'api/v1/offices/' + payload.data.id + '?include=addresses',
        payload
      );
      commit('setStockistCenter', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setStockistCenter', {});
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchStockistsCenter(
    { commit, rootGetters },
    { keyword, pageNumber, pageSize, search, ...others }
  ) {
    commit('setLoader', true);
    const office_categories =
      rootGetters['office_categories/getAvailableOfficeCategories'];
    let stockist_office_category_id = office_categories.filter(function (el) {
      return el.attributes.name === 'Stockist Center';
    });
    const params = { ...others };
    let filter = [];
    let page = [];
    if (keyword && keyword !== '') filter['code'] = keyword;
    if (pageNumber && pageNumber !== '') page['number'] = pageNumber;
    if (pageSize) page['size'] = pageSize;
    if (search && search !== '') filter['search'] = search;
    Object.keys(page).map((item) => {
      const key = `page[${item}]`;
      Object.assign(params, { [key]: page[item] });
    });
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    Object.assign(params, { include: 'addresses,area' });
    try {
      let response = await ApiService.get(
        'api/v1/office-categories/' +
          stockist_office_category_id[0].id +
          '/offices',
        {
          params,
        }
      );
      commit('setStockistsCenter', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setStockistsCenter', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async fetchOfficeCategory({ commit }) {
    commit('setLoader', true);
    const params = {};
    let filter = [];
    filter['name'] = 'stockist';
    Object.keys(filter).map((item) => {
      const key = `filter[${item}]`;
      Object.assign(params, { [key]: filter[item] });
    });
    try {
      let response = await ApiService.get('api/v1/office-categories', {
        params,
      });
      commit('setOfficeCategory', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setOfficeCategory', {
        data: [],
        meta: {
          page: {},
        },
      });
      commit('setError', error);
    }
    commit('setLoader', false);
  },
  async topupStockistCenter({ commit, dispatch }, payload) {
    commit('setLoader', true);
    try {
      let response = await ApiService.post(
        'api/v1/offices/' + payload.id + '/-actions/topUp',
        payload.data
      );
      commit('setStockistCenter', response.data);
      commit('setError', null);
      commit('setLoader', false);
      return response;
    } catch (error) {
      commit('setStockistCenter', {});
      dispatch(
        'alert/createAlert',
        { data: error.response.data, status: 'error' },
        { root: true }
      );
      commit('setError', error.response.data.errors[0].detail);
    }
    commit('setLoader', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
